<template>
  <div class="recharge" v-loading="loading">
    <div class="r-title">充值中心</div>
    <el-row class="r-account">
      <el-col :span="12">
        <i class="fa fa-user-circle-o r-icon mr5"></i>
        <span>充值账号：
          <span class="accout">{{ integral.user_nickname }}</span>
        </span>
      </el-col>
      <el-col :span="12" style="text-align: right">
        可用余额：
        <span class="c-orange">{{ integral.balance }}</span>
        元
      </el-col>
    </el-row>
    <el-form :model="rechargeFormData" :rules="rechargeRules" ref="rechargeRef" label-width="100px">
      <el-form-item label="充值项目：" prop="rechargeValue">
        <el-radio-group v-model="rechargeFormData.rechargeValue">
          <el-radio :label="'' + 100" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                <span class="i-money">100</span>
                <span class="i-text">元</span>
              </li>
              <!-- <li class="r-present">(赠送100积分)</li> -->
              <li class="r-money">售价&nbsp;100元</li>
            </ul>
          </el-radio>
          <el-radio :label="'' + 300" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                <span class="i-money">300</span>
                <span class="i-text">元</span>
              </li>
              <!-- <li class="r-present">(赠送300积分)</li> -->
              <li class="r-money">售价&nbsp;300元</li>
            </ul>
          </el-radio>
          <el-radio :label="'' + 500" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                <span class="i-money">500</span>
                <span class="i-text">元</span>
              </li>
              <!-- <li class="r-present">(赠送500积分)</li> -->
              <li class="r-money">售价&nbsp;500元</li>
            </ul>
          </el-radio>
          <el-radio :label="'' + 1000" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                <span class="i-money">1000</span>
                <span class="i-text">元</span>
              </li>
              <!-- <li class="r-present">(赠送1000积分)</li> -->
              <li class="r-money">售价&nbsp;1000元</li>
            </ul>
          </el-radio>
          <el-radio :label="''" border size="medium" style="margin: 0px; margin-top: 20px; width: 360px">
            <el-input placeholder="其他金额" v-model="inputValue" @input="inputVal($event)" @change="changeVal($event)">
              <i slot="suffix" style="line-height: 100px">元</i>
            </el-input>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="支付金额：">
        <div class="p-money">
          <span>{{ payAmount }}</span>&nbsp;元
        </div>
      </el-form-item>
      <el-form-item label="支付方式：" prop="payTypeValue">
        <el-radio-group v-model="rechargeFormData.payTypeValue" class="pay-Type">
          <el-radio :label="'' + 1" border size="medium">
            <div class="p-weixin">
              <i class="fa fa-weixin"></i>
              &nbsp;
              <span>微信支付</span>
            </div>
          </el-radio>
          <!-- <el-radio :label="'' + 2" border size="medium">
            <div class="p-alipay">
              <i class="school icon-zhifubaozhifu" style="color: #1677ff"></i>
              &nbsp;
              <span>支付宝支付</span>
            </div>
          </el-radio> -->
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="r-btn">
      <div class="b-text" @click="handleRecharge()">立即充值</div>
    </div>
  </div>
</template>

<script>
  import {
    createCompanyOrderApi,
    getCompanyAmountApi,
    aliPay
  } from '@/api/account'
  export default {
    name: "recharge",
    data() {
      return {
        rechargeFormData: {
          rechargeValue: "100",
          payTypeValue: "1", //支付方式
          recharge_type: '0', //余额充值
        },
        inputValue: "", //手动输入值
        qrcode: "", //微信二维码
        rechargeRules: {
          rechargeValue: [{
            required: true,
            message: '请选择充值项目',
            trigger: 'change'
          }],
          payTypeValue: [{
            required: true,
            message: '请选择支付方式',
            trigger: 'change'
          }]
        },
        integral: "0.00",
        code_url: "",
        loading: false,
      }

    },
    computed: {     
      payAmount() {
        if (!this.rechargeFormData.rechargeValue) {
          return 0
        } else {
          return this.rechargeFormData.rechargeValue
        }
      },
    },
    created() {
      this.rechargeDataInit()
    },
    methods: {
      //页面数据初始化
      async rechargeDataInit() {
        this.loading = true
        const {
          data: res
        } = await getCompanyAmountApi()
        if (res.result.length != 0) {
          this.integral = res.result
        }
        this.loading = false
      },
      inputVal() {
        var pattern = /^[1-9][0-9]*$/ //正整数的正则表达式
        // 不符合正整数时
        if (!pattern.test(this.inputValue)) {
          // input 框绑定的内容为空
          this.$message.error('输入金额为正整数')
          this.inputValue = ''
        }
        this.rechargeFormData.rechargeValue = this.inputValue
      },
      changeVal(value) {
        if (value < 1) {
          // input 框绑定的内容为空
          this.$message.error('输入金额不能小于1')
          this.inputValue = ''
        }
        this.rechargeFormData.rechargeValue = this.inputValue
      },
      handleRecharge() {
        let newRechargeData = {
          amount: this.rechargeFormData.rechargeValue,
          pay_way: this.rechargeFormData.payTypeValue,
          recharge_type: this.rechargeFormData.recharge_type
        }
        //获取订单号
        this.$refs.rechargeRef.validate(async (valid) => {
          if (!valid) return;
          const {
            data: res
          } = await createCompanyOrderApi(newRechargeData)
          // let orderId = res.result.id
          //将支付金额保存在store中
          this.$store.commit('saveRechargeValue', this.rechargeFormData.rechargeValue)
          //跳转至wx支付
          if (this.rechargeFormData.payTypeValue == 1) {
            this.$router.push({path:'/pc/wxPay', query:{code: res.result.code_url}})
          } else if (this.rechargeFormData.payTypeValue == 2) {
            //跳转至aliPay支付
            const {
              data: res
            } = await aliPay(orderId)
            let routerData = this.$router.resolve({
              path: '/pc/aliPay',
              query: {
                htmlData: res.result.form
              }
            })
            // 打开新页面
            window.open(routerData.href, '_ blank')
          }
        })
      }
    }
  }

</script>

<style lang="less" scoped>
  .recharge {
    .r-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .c-orange {
      color: #ff7125;
      font-size: 20px;
      font-weight: 600;
    }

    .r-account {
      height: 80px;
      line-height: 80px;
      margin: 20px 0px;
      background: #f2f2f2;
      padding: 0px 20px;
    }

    .r-icon {
      font-size: 20px;
    }

    .c-icon {
      width: 100px;
      height: 100px;
      background: #eaca44;
      border-radius: 50%;
    }

    .p-money {
      font-size: 16px;
      color: #ff7125;

      span {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .el-form {
      border-bottom: 2px solid #efefef;
    }

    .r-btn {
      position: relative;
      height: 80px;
      margin: 10px 0px;
      cursor: pointer;

      .b-text {
        width: 200px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ff7125;
        color: #ffff;
        border-radius: 100px;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    /deep/ .el-radio__input {
      display: none;
    }

    .el-radio {
      border: 2px solid #dcdfe6;
      text-align: center;
    }

    .is-bordered {
      border: 2px solid #dcdfe6;
    }

    .is-checked {
      border-color: #ff7125;
    }

    /deep/ .el-radio__input.is-checked+.el-radio__label {
      color: #606266;
    }

    .el-radio--medium.is-bordered {
      margin-top: 0;
      padding-top: 0;
      width: 160px;
      height: 90px;
    }

    .r-project {
      height: 90px;
      font-weight: 600;
    }

    // .r-present {
    //   margin: 10px 0px;
    //   color: #ff7125;
    // }

    .r-integrate {
      color: #ff7125;

      .i-money {
        font-size: 18px;
      }

      .i-text {
        font-weight: 500;
      }

    }

    .r-money {
      color: #b0b0b0;
      margin: 20px 0px;
      font-size: 14px;

    }

    .el-input {
      width: 100%;
      height: 100%;
    }

    /deep/ .el-input__inner {
      width: 100%;
      height: 100%;
      border: 0px;
      font-size: 15px;
      font-weight: 600;
      color: #b0b0b0;
      background: #fafafa;
    }

    /deep/ .el-input__suffix {
      top: -8px;
      right: 25px;

      i {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .p-weixin {
      height: 84px;
      color: #666;

      i {
        color: #06b259;
      }
    }

    .p-alipay {
      i {
        color: #1677ff;
      }
    }

    .pay-Type {
      .el-radio--medium.is-bordered {
        width: 160px;
        height: 50px;
        padding-top: 0px;
      }
    }

    .accout {
      color: #ff7125;
      font-size: 16px;
      font-weight: 600;
    }
  }

</style>
